export const unjspfTheme = {
  palette: {
    primary: {
      main: "#006EB6",
      dark: "#145986",
      contrastText: "#fff",
    },
    secondary: {
      main: "#C62222",
      contrastText: "#fff",
    },
    text: {
      secondary: "#7E7E7E",
    },
  },
  typography: {
    fontFamily: "-apple-system, BlinkMacSystemFont, sans-serif",
    button: {
      fontWeight: 400,
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          textTransform: "none",
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: "none",
        },
      },
    },
  },
};
