import { Box, CircularProgress } from "@mui/material";
import React from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import IntlMessages from "../../../../util/IntlMessages";

const DCoEValidationModal = ({
  loading,
  success,
  error,
  close,
  fullName = "",
}) => {
  return (
    <>
      {/* CoE Loading */}
      {!(success || error) && (
        <SweetAlert
          show={loading}
          custom
          title={<IntlMessages id="pages.profile.coe.loading.title" />}
          confirmBtnStyle={{ display: "none" }}
          openAnim={{ name: "showDCEAlert", duration: 300 }}
          onConfirm={() => {}}
          customIcon={
            <Box className="p-5">
              <CircularProgress size={100} />
            </Box>
          }
        >
          <IntlMessages id="pages.profile.coe.loading.text" />
        </SweetAlert>
      )}
      {/* CoE Valid */}
      <SweetAlert
        show={success}
        success
        title={
          <IntlMessages
            id="pages.profile.coe.success.title"
            values={{ fullName }}
          />
        }
        onConfirm={close}
        openAnim={{ name: "showDCEAlert", duration: 300 }}
        confirmBtnText={<IntlMessages id="pages.profile.coe.success.button" />}
      />
      {/* CoE Invalid */}
      <SweetAlert
        show={error}
        danger
        confirmBtnText={<IntlMessages id="pages.profile.coe.fail.button" />}
        openAnim={false}
        title={<IntlMessages id="pages.profile.coe.fail.title" />}
        onConfirm={close}
      >
        <IntlMessages id="pages.profile.coe.fail.text" values={{ fullName }} />
      </SweetAlert>
    </>
  );
};

export { DCoEValidationModal };
