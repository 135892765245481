import { Box, Button } from "@mui/material";
import cl from "classnames";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import placeholder from "../../../../../assets/images/placeholder.jpg";
import { absolutePath } from "../../../../../constants/Routes";
import { enrolmentsLoadingSelector } from "../../../../../reducers/selectors";
import { capitalizeFirst } from "../../../../../util";
import { ImageModal } from "../ImageModal";
import styles from "./EnrolmentImage.module.scss";

const EnrolmentImage = ({ enrolment }) => {
  const user = enrolment?.user;
  const firstName = capitalizeFirst(user?.first_name || "-");
  const middleName = user?.middle_name && capitalizeFirst(user?.middle_name);
  const lastName = capitalizeFirst(user?.last_name || "-");
  const beneficirayPicture = enrolment?.recent_picture || placeholder;

  const loading = useSelector(enrolmentsLoadingSelector);
  const navigate = useNavigate();
  const [beneficiaryPicOpen, setBeneficiaryPicOpen] = React.useState(false);

  return (
    <>
      {!loading && (
        <Box
          className={cl(
            styles.enrolmentImageBox +
              " card-header-content d-flex flex-column justify-content-center h-100"
          )}
        >
          <Button
            className="rounded-circle expand"
            onClick={() => {
              setBeneficiaryPicOpen(true);
            }}
            aria-label="expand image of benificiary"
          >
            <div
              className="rounded-circle card-header-pic mt-4 mb-1"
              style={{
                width: 180,
                height: 180,
                backgroundImage: `url(${beneficirayPicture})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center",
                cursor: "pointer",
                border: "2px solid #fff",
              }}
              alt="image of benificiary"
            />
          </Button>
          {beneficiaryPicOpen && (
            <ImageModal
              className="expanded-card-header-pic"
              open={beneficiaryPicOpen}
              image={beneficirayPicture}
              altText="expanded image of benificiary"
              handleCloseModal={() => {
                setBeneficiaryPicOpen(false);
              }}
            />
          )}
          <Button
            onClick={() => navigate(absolutePath(`beneficiary/${user?.id}`))}
            style={{
              textTransform: "none",
            }}
          >
            <h3 className={cl(styles.h1Title, " mb-2 text-white fw-bold mb-4")}>
              <span className={cl(styles.h1TitleSpan)}>
                {middleName ? `${firstName} ${middleName}` : `${firstName}`}{" "}
              </span>
              <span className={cl(styles.h1TitleSpan)}>{`${lastName}`}</span>
            </h3>
          </Button>
        </Box>
      )}
    </>
  );
};

export { EnrolmentImage };
